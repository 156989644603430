import React from "react";

export default function AffirmPrequalify(props) {
  const getPrice = (price) => {
    if (price) {
      return price + "00";
    }
    return "00";
  };
  return (
    <>
      {props.price > 0 && props.price && (
        <div className="product-affirm">
          <p
            className="affirm-as-low-as"
            data-amount={getPrice(props.newPrice || props.price)}
            data-affirm-color="black"
            data-learnmore-show="true"
            data-page-type={props.preQualifyNow ? "category" : "product"}
          >
            {!props.preQualifyNow && (
              <a
                className="affirm-modal-trigger"
                aria-label="Prequalify now about Affirm Financing (opens in modal)"
                onClick={e => e.preventDefault()}
              >
                Prequalify now
              </a>
            )}
          </p>
        </div>
      )}
    </>
  );
}
